import { useState } from 'react'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Dialog } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import iCalendarPlugin from '@fullcalendar/icalendar'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'

import { mobileStyles } from './commonStyles'
import { TUTORING_EVENTS_SOURCES } from '../utils/events'
import { EventDetails } from '../utils/types'
import useAnalytics from '../hooks/useAnalytics'

const useStyles = makeStyles(
  theme => ({
    // Mobile
    ...mobileStyles(theme),

    // Web
    root: {
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      maxHeight: '100%',
    },

    announcement: {
      display: 'flex',
      color: '#E00',
      fontWeight: 'bold',
      fontSize: 20,
    },

    footer1: {
      marginBottom: theme.spacing(1),
      fontWeight: 'bold',
    },

    footer2: {
      marginBottom: theme.spacing(3),
    },

    fullCalendar: {
      display: 'flex',
      flex: 1,
      maxHeight: '75vh',
      overflowY: 'auto',
      padding: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },

    fullCalendarSmall: {
      display: 'grid',
      flex: 1,
      padding: theme.spacing(1),
    },

    dialog: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(3),
      margin: theme.spacing(3),
      fontSize: '1.0rem',
    },

    eventTitle: {},

    eventDateTime: {
      fontWeight: 'bold',
    },
  }),
  { name: 'TutoringSchedule' }
)

const TutoringSchedule = (props: any) => {
  const classes: any = useStyles(props)

  const [open, setOpen] = useState(false)
  const [eventInfo, setEventInfo] = useState<EventDetails>({})

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  useAnalytics()

  const handleClose = () => {
    setOpen(false)
  }

  return isSmall ? (
    <div className={classes.mobileRoot}>
      <div className={classes.fullCalendarSmall}>
        <FullCalendar
          plugins={[listPlugin, iCalendarPlugin]}
          initialView="listWeek"
          nowIndicator={true}
          eventSources={TUTORING_EVENTS_SOURCES}
        />
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.announcement}>
        Join the&nbsp;
        <code>#tutoring</code>
        &nbsp;channel on&nbsp;
        <a href="https://lmucs.slack.com" target="_blank" rel="noreferrer">
          LMU CS Slack
        </a>
        &nbsp;for real-time schedule change announcements!
      </Typography>
      <div className={classes.fullCalendar}>
        <FullCalendar
          plugins={[timeGridPlugin, dayGridPlugin, iCalendarPlugin]}
          initialView="timeGridWeek"
          nowIndicator={true}
          eventSources={TUTORING_EVENTS_SOURCES}
          eventClick={info => {
            const details: EventDetails = {
              title: info.event.title,
              start: info.event.start,
              end: info.event.end,
              startISO: DateTime.fromJSDate(info.event.start).toISO(),
              endISO: DateTime.fromJSDate(info.event.end).toISO(),
              description: info.event.extendedProps.description,
            }

            setEventInfo(details)
            setOpen(true)
          }}
          // Mouse enter event
          // https://fullcalendar.io/docs/eventMouseEnter
          eventMouseEnter={info => {
            // alert(info.event.title)
          }}
          slotMinTime="09:00:00"
          slotMaxTime="23:00:00"
          contentHeight="auto"
        />
      </div>

      <Typography variant="body1" className={classes.footer1}>
        Please respect the posted hours for each TA. They have their own homework to do and exams to
        study for!
      </Typography>

      <Typography variant="body2" className={classes.footer2}>
        For questions, comments, or concerns, please contact{' '}
        <a href="mailto:masao.kitamura@lmu.edu">Masao Kitamura</a> or use our{' '}
        <Link to="/feedback">anonymous feedback form</Link>.
      </Typography>

      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.dialog,
        }}
      >
        <div className={classes.eventDateTime}>
          {DateTime.fromJSDate(eventInfo.start).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
        </div>
        <div className={classes.eventTitle}>{eventInfo.title}</div>
        <div>
          {DateTime.fromJSDate(eventInfo.start).toLocaleString(DateTime.TIME_SIMPLE)} -&nbsp;
          {DateTime.fromJSDate(eventInfo.end).toLocaleString(DateTime.TIME_SIMPLE)}
        </div>
        <div>{eventInfo.description}</div>
      </Dialog>
    </div>
  )
}

export default TutoringSchedule
