import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet-async'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import useAnalytics from '../hooks/useAnalytics'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      backgroundColor: theme.palette.common.white,
      overflow: 'hidden',
      justifyContent: 'center',
    },

    cards: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },

    card: {
      maxWidth: '20rem',
      minHeight: '16rem',
      margin: theme.spacing(2),
    },

    cardMedia: {
      height: 140,
    },
  }),
  { name: 'Lab' }
)

const ANNEX_CHROMECASTS_URL =
  'https://docs.google.com/presentation/d/e/2PACX-1vQimid3ZCc2hej75mElAbn2NYBtUd7LPZE-N97jGBvcvTNQjIDquXaDNzRyUWXHLWrSEfVl64WrxV8S/pub'

const AV_RECOMMENDED_HDMI_USAGE =
  'https://docs.google.com/presentation/d/e/2PACX-1vThkNcz2DNtmUJJvLyIzvNOwBFJTFf6qH4hSG8te3HKbOnMVH1RiucSjgJE0JYUKJGnY7FiLsu1XNo-/pub'

const AV_TROUBLESHOOTING_URL =
  'https://docs.google.com/presentation/d/e/2PACX-1vSb9aX73_PYRnKP6GXkBHxROgBJZjfic7v91_BPlCtMe1hhLiEqnHO9GyrbqyYr5gf-EO3pBNCM0VCJ/pub'

const Lab = props => {
  const classes = useStyles(props)

  useAnalytics()

  return (
    <div className={classes.root}>
      <Helmet>
        <title>CS Lab Resources</title>
      </Helmet>
      <div className={classes.cards}>
        <Card
          className={classes.card}
          onClick={() => window.open(AV_TROUBLESHOOTING_URL, '_blank', 'noreferrer')}
        >
          <CardActionArea>
            <CardMedia className={classes.cardMedia} image="/assets/AV_switch.jpg" />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Projector Troubleshooting
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card
          className={classes.card}
          onClick={() => window.open(ANNEX_CHROMECASTS_URL, '_blank', 'noreferrer')}
        >
          <CardActionArea>
            <CardMedia className={classes.cardMedia} image="/assets/annex_chromecasts.jpg" />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Switch Projectors to Chromecast Backup
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card
          className={classes.card}
          onClick={() => window.open(AV_RECOMMENDED_HDMI_USAGE, '_blank', 'noreferrer')}
        >
          <CardActionArea>
            <CardMedia className={classes.cardMedia} image="/assets/lab_AV_recommended_hdmi.jpg" />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Recommended HDMI Usage
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </div>
  )
}

export default Lab
