import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import { makeStyles, ThemeProvider } from '@material-ui/core/styles'

import CssBaseline from '@material-ui/core/CssBaseline'

import Header from './components/Header'

import HeaderContentLayout from './layouts/HeaderContentLayout'

import EmbedTest from './pages/EmbedTest'
import FeedbackForm from './pages/FeedbackForm'
import Resources from './pages/Resources'
import TeachingSchedule from './pages/TeachingSchedule'
import TutoringSchedule from './pages/TutoringSchedule'

import theme from './themes/default'
import Lab from './pages/Lab'
import { useDetectAdBlock } from 'adblock-detect-react'

const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: theme.palette.common.white,
      overflow: 'hidden',
    },
  }),
  { name: 'App' }
)

const App = props => {
  const classes = useStyles(props)

  const adBlockDetected = useDetectAdBlock()
  console.info('AdBlock detected:', adBlockDetected)

  return (
    <div className={classes.root}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          {/* https://material-ui.com/components/css-baseline/ */}
          <CssBaseline />
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to="/tutoring-schedule" />} />
              <Route
                path="/tutoring-schedule"
                element={
                  <HeaderContentLayout
                    title="Computer Science Tutoring Schedule"
                    header={Header}
                    content={TutoringSchedule}
                  />
                }
              />
              <Route
                path="/teaching"
                element={
                  <HeaderContentLayout
                    title="Computer Science Teaching Schedule"
                    header={Header}
                    content={TeachingSchedule}
                  />
                }
              />
              <Route
                path="/resources"
                element={
                  <HeaderContentLayout
                    title="Computer Science FAQ and Resources"
                    header={Header}
                    content={Resources}
                  />
                }
              />
              <Route
                path="/lab"
                element={
                  <HeaderContentLayout
                    title="Computer Science Lab Resources"
                    header={Header}
                    content={Lab}
                  />
                }
              />
              <Route
                path="/embed-test"
                element={<HeaderContentLayout header={Header} content={EmbedTest} />}
              />
              <Route
                path="/feedback"
                element={
                  <HeaderContentLayout
                    title="Computer Science Feedback"
                    header={Header}
                    content={FeedbackForm}
                  />
                }
              />
            </Routes>
          </Router>
        </ThemeProvider>
      </HelmetProvider>
    </div>
  )
}

export default App
