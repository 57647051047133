import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr', // header fits to internal content; content maximizes to page
      width: '100vw',
      height: '100vh',
      margin: 0,
      padding: 0,
    },

    header: {},

    content: {},
  }),
  { name: 'HeaderContentLayout' }
)

const HeaderContentLayout = props => {
  const classes = useStyles(props)

  const { header: Header, content: Content, ...rest } = props

  return (
    <div className={classes.root}>
      <div className={classes.header}>{Header ? <Header {...rest} /> : 'HEADER MISSING'}</div>
      <div className={classes.content}>{Content ? <Content {...rest} /> : 'CONTENT MISSING'}</div>
    </div>
  )
}

export default HeaderContentLayout
