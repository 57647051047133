// One way of returning a JSS map block
const mobileStyles: any = theme => ({
  mobileRoot: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
    justifyContent: 'center',
  },

  mobileIframe: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    flexGrow: 1,
    border: 'none',
    margin: 0,
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    minWidth: '100%',
  },
})

export { mobileStyles }
