import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

// import useMediaQuery from '@material-ui/core/useMediaQuery'
import useMediaQuery from '@mui/material/useMediaQuery'

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'center', // center on the cross axis
      justifyContent: 'center', // max space between items
      backgroundColor: theme.palette.common.white,
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },

    mobile: {
      display: 'flex',
      alignItems: 'center', // center on the cross axis
      justifyContent: 'center', // max space between items
      backgroundColor: theme.palette.common.white,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },

    header: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },

    title: {
      display: 'flex',
      flex: 1,
      fontWeight: 'bold',
    },

    controls: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      // marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      marginRight: theme.spacing(2),
    },

    buttonTest: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      marginRight: theme.spacing(2),
    },

    buttonLeftTest: {
      marginLeft: theme.spacing(2),
    },
  }),
  { name: 'Header' }
)

const Header = props => {
  const classes = useStyles(props)

  const { title } = props

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return isSmall ? (
    // MOBILE
    <div className={classes.mobile}>
      <header className={classes.header}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.controls}>
          <div>
            <Button
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              Menu
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose} component={Link} to="/tutoring-schedule">
                Tutoring Schedule
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/teaching">
                Teaching Schedule
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/resources">
                FAQ and Resources
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/feedback">
                Feedback
              </MenuItem>
            </Menu>
          </div>
        </div>
      </header>
    </div>
  ) : (
    // DESKTOP
    <div className={classes.root}>
      <header className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          {title || TITLE}
        </Typography>
        <div className={classes.controls}>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.buttonTest} // no
              href="/tutoring-schedule"
              variant="contained"
              color="primary"
              // style={{
              //   marginRight: 10, // yes
              // }}
              classes={{
                root: classes.buttonTest, // no
                text: classes.buttonTest, // no
                contained: classes.buttonTest, // no
              }}
            >
              Tutoring Schedule
            </Button>
          </div>
          {/* <Button className={classes.button} href="/roster" variant="contained" color="primary">
            TA Photo Roster
          </Button> */}
          <div className={classes.buttonContainer}>
            <Button
              className={classes.buttonLeftTest}
              href="/teaching"
              variant="contained"
              color="primary"
            >
              Teaching Schedule
            </Button>
          </div>
          <div className={classes.buttonContainer}>
            <Button className={classes.button} href="/lab" variant="contained" color="primary">
              Lab
            </Button>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              href="/resources"
              variant="contained"
              color="primary"
            >
              FAQ and Resources
            </Button>
          </div>
          <div className={classes.buttonContainer}>
            <Button className={classes.button} href="/feedback" variant="contained" color="primary">
              Feedback
            </Button>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Header
