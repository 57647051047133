import { useState } from 'react'
import { DateTime } from 'luxon'

import { Dialog } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import iCalendarPlugin from '@fullcalendar/icalendar'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'

import { mobileStyles } from './commonStyles'

import { TEACHING_EVENTS_SOURCES } from '../utils/events'
import { EventDetails } from '../utils/types'
import useAnalytics from '../hooks/useAnalytics'

const useStyles = makeStyles(
  theme => ({
    // Mobile
    ...mobileStyles(theme),

    // Web
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      backgroundColor: theme.palette.common.white,
      overflow: 'hidden',
      justifyContent: 'flex-start',
    },

    fullCalendar: {
      display: 'flex',
      flex: 1,
      maxHeight: '85vh',
      overflowY: 'auto',
      padding: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },

    fullCalendarSmall: {
      display: 'grid',
      flex: 1,
      padding: theme.spacing(1),
    },

    dialog: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(3),
      margin: theme.spacing(3),
      fontSize: '1.0rem',
    },

    eventTitle: {},

    eventDateTime: {
      fontWeight: 'bold',
    },
  }),
  { name: 'TeachingSchedule' }
)

const TeachingSchedule = (props: any) => {
  const classes: any = useStyles(props)

  const [open, setOpen] = useState(false)
  const [eventInfo, setEventInfo] = useState<EventDetails>({})

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  useAnalytics()

  const handleClose = () => {
    setOpen(false)
  }

  console.log('Teach isSmall: ', isSmall)

  return isSmall ? (
    <div className={classes.mobileRoot}>
      <div className={classes.fullCalendarSmall}>
        {''}
        <FullCalendar
          plugins={[listPlugin, iCalendarPlugin]}
          initialView="listWeek"
          nowIndicator={true}
          eventSources={TEACHING_EVENTS_SOURCES}
        />
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <div className={classes.fullCalendar}>
        <FullCalendar
          plugins={[timeGridPlugin, dayGridPlugin, iCalendarPlugin]}
          initialView="timeGridWeek"
          nowIndicator={true}
          eventSources={TEACHING_EVENTS_SOURCES}
          eventClick={info => {
            const details: EventDetails = {
              title: info.event.title,
              start: info.event.start,
              end: info.event.end,
              startISO: DateTime.fromJSDate(info.event.start).toISO(),
              endISO: DateTime.fromJSDate(info.event.end).toISO(),
              description: info.event.extendedProps.description,
            }

            setEventInfo(details)
            setOpen(true)
          }}
          slotMinTime="08:00:00"
          slotMaxTime="23:00:00"
          contentHeight="auto"
        />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.dialog,
        }}
      >
        <div className={classes.eventDateTime}>
          {DateTime.fromJSDate(eventInfo.start).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
        </div>
        <div className={classes.eventTitle}>{eventInfo.title}</div>
        <div>
          {DateTime.fromJSDate(eventInfo.start).toLocaleString(DateTime.TIME_SIMPLE)} -&nbsp;
          {DateTime.fromJSDate(eventInfo.end).toLocaleString(DateTime.TIME_SIMPLE)}
        </div>
      </Dialog>
    </div>
  )
}

export default TeachingSchedule
