import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: theme.palette.common.white,
    },
  }),
  { name: 'EmbedTest' }
)

const EmbedTest = props => {
  const classes = useStyles(props)

  // <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=ZXAzdHNyMjZ0bWJqMHZqNzZuZ2p0c2R2OXNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=YnMyYTN2NjF2ZDg3NXZlcDd0Z3FmYWxnajRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=a3JuaTVoOTJnMmYxdm82OTZtYWRpMDhyMWNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=OGRxbmU3NzNjaXNkOWIxdHQxcTBtaGNpODRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23E4C441&amp;color=%23cc00c8&amp;color=%238E24AA&amp;color=%2333B679" style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"></iframe>

  return (
    <div className={classes.root}>
      <main>
        {/* TODO: https://stackoverflow.com/questions/9975810/make-iframe-automatically-adjust-height-according-to-the-contents-without-using */}
        <iframe
          title="not visible"
          src="https://docs.google.com/document/d/e/2PACX-1vSyxP84YSRe4SZCPEGIL2JfINSF9GPSsZ9pDT0I1s6Xai-Z4LupweynqaPVvA4wV-_jyTpTRERY_jjL/pub?embedded=true"
          width="1200" // required
          height="600" // required
        ></iframe>
      </main>
    </div>
  )
}

export default EmbedTest
