import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useAnalytics from '../hooks/useAnalytics'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      backgroundColor: theme.palette.common.white,
      overflow: 'hidden',
      justifyContent: 'center',
    },

    iframe: {
      display: 'flex',
      justifyContent: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      flexGrow: 1,
      border: 'none',
      margin: 0,
      padding: 0,
      minWidth: 900,
    },

    mobileDocIframe: {
      display: 'flex',
      justifyContent: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      flexGrow: 1,
      border: 'none',
      margin: 0,
      padding: 0,
      minWidth: window.innerWidth,
      minHeight: window.innerHeight,
    },
  }),
  { name: 'Resources' }
)

const Resources = props => {
  const classes = useStyles(props)

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  useAnalytics()

  return isSmall ? (
    <div className={classes.root}>
      <iframe
        title="not visible"
        className={classes.mobileDocIframe}
        src="https://docs.google.com/document/d/e/2PACX-1vSyxP84YSRe4SZCPEGIL2JfINSF9GPSsZ9pDT0I1s6Xai-Z4LupweynqaPVvA4wV-_jyTpTRERY_jjL/pub?embedded=true"
      ></iframe>
    </div>
  ) : (
    <div className={classes.root}>
      <iframe
        title="not visible"
        className={classes.iframe}
        src="https://docs.google.com/document/d/e/2PACX-1vSyxP84YSRe4SZCPEGIL2JfINSF9GPSsZ9pDT0I1s6Xai-Z4LupweynqaPVvA4wV-_jyTpTRERY_jjL/pub?embedded=true"
      ></iframe>
    </div>
  )
}

export default Resources
