// https://stackoverflow.com/a/73295380/10415969
import ga4 from 'react-ga4'
import { GOOGLE_ANALYTICS_TRACKING_ID } from './constants'

const TRACKING_ID = GOOGLE_ANALYTICS_TRACKING_ID
const isProduction = process.env.NODE_ENV === 'production'

export const init = () =>
  ga4.initialize(TRACKING_ID, {
    testMode: !isProduction,
  })

export const sendEvent = (name: string) =>
  ga4.event('screen_view', {
    app_name: 'myApp',
    screen_name: name,
  })

export const sendPageview = (path: string) =>
  ga4.send({
    hitType: 'pageview',
    page: path,
  })
