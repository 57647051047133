import { makeStyles } from '@material-ui/core/styles'
import useAnalytics from '../hooks/useAnalytics'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      backgroundColor: theme.palette.common.white,
      overflow: 'hidden',
      justifyContent: 'center',
    },

    iframe: {
      display: 'flex',
      justifyContent: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      flexGrow: 1,
      border: 'none',
      margin: 0,
      padding: 0,
      minWidth: 800,
    },
  }),
  { name: 'FeedbackForm' }
)

const FeedbackForm = props => {
  const classes = useStyles(props)

  useAnalytics()

  return (
    <div className={classes.root}>
      <iframe
        title="not visible"
        className={classes.iframe}
        src="https://docs.google.com/forms/d/e/1FAIpQLSe5mDO9CNLReV-ew8L1Q_Me8wlQMvfAbcNdj6FRdFQTFyT9jg/viewform?embedded=true"
        width="640"
        height="435"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
    </div>
  )
}

export default FeedbackForm
